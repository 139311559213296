import * as React from "react";
import { styled } from "@mui/system";
import { InputAdornment, TextField } from "@mui/material";

export const InputNumber = styled(
  React.forwardRef((props, ref) => {
    return (
      <TextField
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root": { fontSize: "20px" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                "&.MuiInputAdornment-root.MuiInputAdornment-root .MuiTypography-root":
                  {
                    color: "white",
                    background: "#008fc6",
                    padding: "5px",
                    borderRadius: "3px",
                    fontFamily: "Inter",
                  },
              }}
              position="start"
            >
              {props.startAdornmentValue && props.startAdornmentValue}
            </InputAdornment>
          ),
        }}
        {...props}
        ref={ref}
      />
    );
  }),
  {
    name: "InputNumber",
    slot: "Root",
    overridesResolver: (props, styles) => [
      props.disabled ? styles.disabled : null,
      styles.root,
    ],
  }
)(({ theme }) => ({}));
