import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBar } from "../navigation/TopBar";
import { PageSection } from "../components/PageSection";
import ContactCard from "../components/ContactCard";
import { contactlist } from "../store/data";

export const Contacts = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <TopBar /> */}
      <Box flexDirection="row" display="flex" width="100%">
        <PageSection>
          <Typography gutterBottom variant="h4">
            Kontakte (Furnizues & PCT)
          </Typography>
          {contactlist?.map((item) => (
            <ContactCard key={item?.name} data={item} />
          ))}
        </PageSection>
      </Box>
    </>
  );
};
