import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CompanyCard from "../components/CompanyCard";
import Select from "../UI/Select";
import { InputNumber } from "../UI/InputNumber";
import Slider from "../UI/Slider";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APP_URL } from "../services/http-common";
import { TopBar } from "../navigation/TopBar";
import CardList from "../components/CardList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const filterId = location?.state?.filterId;

  const [filterProduct, setFilterProduct] = React.useState(filterId ?? "");
  const [fasha, setFasha] = React.useState("");
  const [stateListFasha, setStateListFasha] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [companies, setCompanies] = React.useState(null);
  const [promotional, setPromotional] = React.useState(false);
  const [vipCompany, setVipCompany] = React.useState(false);
  const [state, setState] = React.useState({
    maxValue: 100000000,
    minValue: 0,
  });
  const [sortType, setSortType] = React.useState("asc");

  const [filterState, setFilterState] = React.useState({
    vip: false,
    fashaMax: 1000000,
    fashaMin: 0,
    zona: "",
    fashaMinUnit: "kw",
  });
  const mobileMode = useMediaQuery("(max-width:900px)");
  const laptopMode = useMediaQuery(
    "((max-width: 1300px) and (min-width:801px))"
  );
  const largeMode = useMediaQuery("(min-width: 1301px)");

  const currencyList = ["ALL", "EUR", "USD"];
  const unitList = ["kw", "mv"];
  const zonaList = [
    "Qarku i Beratit",
    "Qarku i Dibrës",
    "Qarku i Durrësit",
    "Qarku i Elbasanit",
    "Qarku i Fierit",
    "Qarku i Gjirokastrës",
    "Qarku i Korçës",
    "Qarku i Kukësit",
    "Qarku i Lezhës",
    "Qarku i Shkodrës",
    "Qarku i Tiranës",
    "Qarku i Vlorës",
  ];

  const handleChange = (event) => {
    setFilterProduct(event.target.value);
    var productTemp = products.find((item) => {
      if (item.id === event.target.value) {
        return item.ranges;
      }
    });
    setFasha("");
    setStateListFasha(productTemp?.ranges);
  };
  const handleChangeFasha = (event) => {
    setFasha(event.target.value);
  };

  const [valueRange, setValueRange] = React.useState([20, 37]);

  const handleRangeChange = (event, newValue) => {
    setValueRange(newValue);
  };

  useEffect(() => {
    if (filterProduct !== "" && fasha === "") {
      axios
        .get(APP_URL + "public/product/" + filterProduct + "/prices")
        .then((data) => {
          // setCompanies(data?.data);

          const datatest = data?.data;
          const jsontemp = [];
          datatest?.map((item) => {
            const tempRange = item?.productPriceRanges;
            tempRange?.map((itemRange) => {
              jsontemp.push({ company: item.company, ...itemRange });
            });
          });
          setCompanies(jsontemp);
          if (promotional) {
            getPromotionalProduct(jsontemp);
          }
        });
    }
  }, [filterProduct, promotional]);

  useEffect(() => {
    if (filterProduct !== "" && fasha !== "") {
      var tempVip = false;
      var search = "";
      var fashaMax = "fashaMax=";
      var fashaMin = "fashaMin=";
      var zona = "";

      if (filterState.fashaMax !== "") {
        search = "?&search=fashaMax=" + filterState.fashaMax;
      }
      if (filterState.fashaMin !== "") {
        search = search + "&fashaMin=" + filterState.fashaMin;
      }

      if (filterState.zona !== "") {
        search = search + "&zona=" + filterState.zona;
      }

      axios
        .post(
          APP_URL +
            "public/product/" +
            filterProduct +
            "/prices/" +
            fasha +
            search
        )
        .then((data) => {
          setCompanies(data?.data?.content);
          if (promotional) {
            getPromotionalProduct(data?.data?.content);
          }
        });
    } else {
      // if (filterProduct !== "") {
      axios
        .get(APP_URL + "public/product/" + filterProduct + "/prices")
        .then((data) => {
          // setCompanies(data?.data);

          const datatest = data?.data;
          const jsontemp = [];
          datatest?.map((item) => {
            const tempRange = item?.productPriceRanges;
            tempRange?.map((itemRange) => {
              jsontemp.push({ company: item.company, ...itemRange });
            });
          });
          setCompanies(jsontemp);
          if (promotional) {
            getPromotionalProduct(jsontemp);
          }
        });
      // }
    }
  }, [fasha, promotional]);

  useEffect(() => {
    axios.get(APP_URL + "public/product").then((data) => {
      setProducts(data?.data);
      if (filterId) {
        var productTemp = data?.data.find((item) => {
          if (item.id === filterId) {
            return item.ranges;
          }
        });
        setStateListFasha(productTemp?.ranges);
      }
    });
  }, []);

  const applyfilter = () => {
    if (filterProduct !== "" && fasha === "") {
      axios
        .get(APP_URL + "public/product/" + filterProduct + "/prices")
        .then((data) => {
          // setCompanies(data?.data);

          const datatest = data?.data;
          const jsontemp = [];
          datatest?.map((item) => {
            const tempRange = item?.productPriceRanges;
            tempRange?.map((itemRange) => {
              jsontemp.push({ company: item.company, ...itemRange });
            });
          });
          setCompanies(jsontemp);

          if (promotional) {
            getPromotionalProduct(jsontemp);
          } else {
            getRangeProduct(jsontemp);
          }
        });
    } else {
      // if (filterProduct !== "") {

      var search = "";

      if (filterState.fashaMax !== "") {
        search = "?&search=fashaMax=" + filterState.fashaMax;
      }
      if (filterState.fashaMin !== "") {
        search = search + "%26fashaMin=" + filterState.fashaMin;
      }
      if (filterState.fashaMinUnit !== "") {
        search = search + "%26fashaMinUnit=" + filterState.fashaMinUnit;
      }
      if (filterState.zona !== "") {
        search = search + "%26zona=" + filterState.zona;
      }
      if (filterState.vip === true) {
        search = search + "%26vip=" + filterState.vip;
      }

      axios
        .post(
          APP_URL +
            "public/product/" +
            filterProduct +
            "/prices/" +
            fasha +
            search
        )
        .then((data) => {
          setCompanies(data?.data?.content);
          if (promotional) {
            getPromotionalProduct(data?.data?.content);
          } else {
            getRangeProduct(data?.data?.content);
          }
        });
      // }
    }
  };

  const handleChangePromocional = (event) => {
    setPromotional(!promotional);
  };

  // const handleChangeVip  = (event) => {
  //   setPromotional(!promotional);
  // };

  function handleChangeVip(event) {
    setFilterState((prevState) => {
      return {
        ...prevState,
        vip: !filterState.vip,
      };
    });
  }

  const handleChangeVipCompany = (event) => {
    setVipCompany(!vipCompany);
  };

  const getPromotionalProduct = (dataList) => {
    const jsontemp = [];
    dataList?.map((itemRange) => {
      if (itemRange.promocional === true) {
        jsontemp.push({ company: itemRange.company, ...itemRange });
      }
    });
    getRangeProduct(jsontemp);
    setCompanies(jsontemp);
  };

  const getVipCompany = (dataList) => {
    const jsontemp = [];
    dataList?.map((itemRange) => {
      if (itemRange.company.vip === true) {
        jsontemp.push({ company: itemRange.company, ...itemRange });
      }
    });
    getRangeProduct(jsontemp);
    setCompanies(jsontemp);
  };

  const getRangeProduct = (dataList) => {
    const jsontemp = [];
    dataList?.map((itemRange) => {
      if (
        Number(state?.minValue) < Number(itemRange?.currentPrice) &&
        Number(itemRange?.currentPrice) < Number(state?.maxValue)
      ) {
        jsontemp.push({ company: itemRange.company, ...itemRange });
      }
    });
    setCompanies(jsontemp);
  };

  const handleClickFiltre = () => {
    applyfilter();
  };
  const handleClickClearFilter = () => {
    setFasha("");
    setPromotional(false);
    setFilterState({
      vip: false,
      fashaMax: 1000000,
      fashaMin: 0,
      zona: "",
      fashaMinUnit: "kw",
    });
    setState({ maxValue: 100000000, minValue: 0 });
  };

  function onChangeMaxFasha(event) {
    setFilterState((prevState) => {
      return {
        ...prevState,
        fashaMax: event.target.value,
      };
    });
  }
  function onChangeMinFasha(event) {
    setFilterState((prevState) => {
      return {
        ...prevState,
        fashaMin: event.target.value,
      };
    });
  }

  function onChangeMaxValue(event) {
    setState((prevState) => {
      return {
        ...prevState,
        maxValue: event.target.value,
      };
    });
  }
  function onChangeMinValue(event) {
    setState((prevState) => {
      return {
        ...prevState,
        minValue: event.target.value,
      };
    });
  }

  const changeZona = (e) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        zona: e.target.value,
      };
    });
  };

  const changeFashaUnit = (e) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        fashaMinUnit: e.target.value,
      };
    });
  };

  const marks = [
    {
      value: 3,
      label: "3",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 21,
      label: "21",
    },
    {
      value: 23,
      label: "23",
    },
  ];

  const orderCompanyPrice = (companyList) => {
    const type = "asc";
    const tempCompanyesOrdery = companyList.sort((a, b) => {
      if (type === "asc") {
        return a.currentPrice - b.currentPrice;
      } else {
        return b.currentPrice - a.currentPrice;
      }
    });
    return tempCompanyesOrdery;
  };

  return (
    <>
      {/* <TopBar /> */}
      <Box
        flexDirection={mobileMode ? "column" : "row"}
        display="flex"
        width="100%"
      >
        <Box sx={{ width: mobileMode ? "auto" : "35%" }}>
          <Box
            sx={{
              marginLeft: mobileMode ? "10px" : laptopMode ? "20px" : "15%",
              marginRight: mobileMode ? "10px" : "0px",
              marginTop: "10px",
              marginBottom: "50px",
              borderRadius: "3px",
              background: "hsla(0,0%,89%,.4)",
              boxShadow: "0 1px 6px -1px rgba(0,0,0,.18)",
              // height: "600px", // temp
              padding: mobileMode
                ? "20px 10px 30px 10px"
                : "40px 20px 60px 20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Kategoritë</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterProduct}
                label="Kategoritë"
                onChange={handleChange}
              >
                {products?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel id="demo-simple-select-label">
                Nivel i Tensionit
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fasha}
                label="Nivel i Tensionit"
                onChange={handleChangeFasha}
              >
                {stateListFasha?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Stack
              sx={{ marginTop: "20px" }}
              flexDirection="column"
              spacing={2}
            >
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    checked={promotional}
                    onClick={(event) => {
                      handleChangePromocional();
                    }}
                  />
                }
                label="Oferta Speciale"
                labelPlacement="start"
              />
            </Stack> */}
            {/* <Stack
              sx={{ marginTop: "20px" }}
              flexDirection="column"
              spacing={2}
            >
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    checked={vipCompany}
                    onClick={(event) => {
                      handleChangeVipCompany();
                    }}
                  />
                }
                label="Vip Kompani"
                labelPlacement="start"
              />
            </Stack> */}
            {/* <Stack
              sx={{ marginTop: "20px" }}
              flexDirection="column"
              spacing={2}
            >
              <Typography variant="label" gutterBottom>
                Range
              </Typography>
              <Slider
                marks={marks}
                // getAriaLabel={() => "Range"}
                max={24}
                min={3}
                value={valueRange}
                onChange={handleRangeChange}
              />
            </Stack> */}
            <Stack
              sx={{ marginTop: "20px" }}
              flexDirection="column"
              spacing={2}
            >
              <InputNumber
                type="number"
                defaultValue={1000000000}
                onChange={onChangeMaxFasha}
                value={filterState.fashaMax}
                startAdornmentValue="max"
                label="Fasha maksimale"
              />
              <InputNumber
                type="number"
                defaultValue={0}
                onChange={onChangeMinFasha}
                value={filterState.fashaMin}
                startAdornmentValue="min"
                label="Fasha minimale"
              />
              <Stack
                sx={{ marginTop: "20px" }}
                flexDirection="row"
                justifyContent="space-between"
                spacing={2}
              >
                {/* <FormControl fullWidth sx={{ width: "170px" }}>
                  <InputLabel id="demo-simple-select-zona">Njesia</InputLabel>
                  <Select
                    labelId="demo-simple-select-njesia"
                    id="demo-simple-njesia"
                    label="Njesia"
                    value={filterState.fashaMinUnit}
                    onChange={changeFashaUnit}
                    MenuProps={{ sx: { maxHeight: "300px" } }}
                  >
                    {unitList?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#575757",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    component="div"
                  >
                    Rendit sipas çmimit
                  </Typography>
                  <Box sx={{ backgroundColor: "#eaeaea", borderRadius: "8px" }}>
                    <IconButton onClick={() => setSortType("asc")}>
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => setSortType("desc")}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Box>
                </Box>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={promotional}
                      onClick={(event) => {
                        handleChangePromocional();
                      }}
                    />
                  }
                  label="Oferta Speciale"
                  labelPlacement="start"
                />
              </Stack>

              <InputNumber
                type="number"
                defaultValue={1000000000}
                onChange={onChangeMaxValue}
                value={state.maxValue}
                startAdornmentValue="max"
                label="Tarifa e lartë (TL)"
              />
              <InputNumber
                type="number"
                defaultValue={0}
                value={state.minValue}
                onChange={onChangeMinValue}
                startAdornmentValue="min"
                label="Tarifa e ulët (TU)"
              />
              <Stack>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-zona">Zona</InputLabel>
                  <Select
                    labelId="demo-simple-select-zona"
                    id="demo-simple-zona"
                    label="Zona"
                    value={filterState.zona}
                    onChange={changeZona}
                    MenuProps={{ sx: { maxHeight: "300px" } }}
                    // SelectProps={{
                    //   MenuProps: {
                    //     sx: { maxHeight: "200px" },
                    //   },
                    // }}
                  >
                    {zonaList?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  value="start"
                  sx={{ marginTop: "20px" }}
                  control={
                    <Switch
                      checked={filterState.vip}
                      onClick={(event) => {
                        handleChangeVip();
                      }}
                    />
                  }
                  label="Furnizues me Performancë të Lartë"
                  labelPlacement="start"
                />
              </Stack>
              <Button
                disabled={filterProduct === "" || fasha === ""}
                variant="contained"
                onClick={handleClickFiltre}
              >
                {" "}
                Apliko Filtrin
              </Button>
              <Button variant="outlined" onClick={handleClickClearFilter}>
                Pastro Filtrin
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ width: mobileMode ? "auto" : "65%" }}>
          <Box sx={{ margin: "10px 20px" }}>
            {
              companies == [] ||
              companies === undefined ||
              companies === null ? (
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    marginTop: "200px",
                    color: "#c8c8c8",
                    fontWeight: "700",
                  }}
                >
                  Nuk ka rezultate që përputhen me filtrat tuaj.
                </Typography>
              ) : (
                <CardList data={companies} sortType={sortType} />
              )
              // (
              //   companies
              //     // ?.sort((a, b) => a.currentPrice - b.currentPrice)
              //     ?.map((item) => (
              //       <CompanyCard
              //         data={item}
              //         key={item.company.name}
              //         companyName={item.company.name}
              //       />
              //     ))
              // )
            }
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
