import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBar } from "../navigation/TopBar";
import { PageSection } from "../components/PageSection";

export const AboutPct = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <TopBar /> */}
      <Box flexDirection="row" display="flex" width="100%">
        <PageSection>
          PCT është platforma e krahasimit të çmimit të energjisë elektrike të
          ofruar në tregun e lirë nga furnizuesit e licencuar. Nëpërmjet
          përzgjedhjes së profilit të konsumatorit si dhe ofertave të
          furnizuesve ofertues, mund të garantohet sigurimi i shërbimit të
          furnizimit në treg të lirë me çmime konkuruese.
        </PageSection>
      </Box>
    </>
  );
};
