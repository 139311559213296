import React from "react";
import { styled } from "@mui/material";
import MUISelect from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Select = styled(
  React.forwardRef((props, ref) => (
    <MUISelect
      spellCheck="false"
      fullWidth
      ref={ref}
      sx={{
        "& legend": {
          display: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          top: 0,
        },
      }}
      IconComponent={KeyboardArrowDownIcon}
      {...props}
    />
  ))
)(({ theme }) => ({
  "&.MuiList-root.MuiMenu-list": {
    marginLeft: "6px",
    marginRight: "6px",
  },
  "& .MuiListItemText-root": {
    margin: 0,
    marginBottom: 4,
  },
}));

export default Select;
