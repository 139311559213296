import axios from "axios";

// export const APP_URL = "http://38.242.210.224:8080/ere-bi/api/v1/"; // test ere
// export const APP_URL = "http://80.90.88.194:8080/ere-bi/api/v1/"; // live ere
// export const APP_URL = "http://127.0.0.1:8080/ere-bi/api/v1/"; // live ere 2
export const APP_URL = "https://pct.ere.gov.al/ere-bi/api/v1/"; // live ere 2

export default axios.create({
  baseURL: APP_URL,
  headers: {
    "Content-type": "application/json",
  },
});
