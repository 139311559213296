import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const QaCard = ({ data }) => {
  const { question = "", link = "#" } = data;
  return (
    <Card sx={{ minWidth: 275, marginBottom: "8px" }}>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          {question}
        </Typography>
        <Link
          href={link}
          sx={{ display: "flex" }}
          target="_blank"
          underline="none"
        >
          Shiko informacion të detajuar këtu{" "}
          <OpenInNewIcon sx={{ marginLeft: "5px" }} />
        </Link>
      </CardContent>
    </Card>
  );
};

export default QaCard;
