import React, { useEffect } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import imageCover from "../assets/banner_new.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { useLocation, useNavigate } from "react-router-dom";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Message } from "./Message";

const pages = [
  { label: "Kryesore", value: "home" },
  { label: "Çfarë është PCT", value: "aboutPct" },
  { label: "Si përdoret Platforma?", value: "howToUse" },
  { label: "Pyetje më të shpeshta (FAQ)", value: "faq" },
  { label: "Kontakte (Furnizues & PCT)", value: "contacts" },
  { label: "Kam një ankesë", value: "complaint" },
];

export const TopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isMainPage, setIsMainPage] = React.useState(false);

  useEffect(() => {
    checkUrl(location.pathname);
    if (location?.pathname === "/") {
      setIsMainPage(true);
    }
  }, [location.pathname]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openEntity = (item, index) => {
    navigate("/" + item?.value);
    setSelectedIndex(index);
    handleCloseNavMenu();
  };

  const checkUrl = (url) => {
    const urlTemp = url.split("/")[1];
    pages?.map((item, index) => {
      if (item.value === urlTemp) {
        setSelectedIndex(index);
        setIsMainPage(false);
      }
    });
  };

  return (
    !isMainPage && (
      <Box>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <ElectricBoltIcon
                sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
              />
              <Typography
                variant="h6"
                noWrap
                onClick={() =>
                  openEntity({ label: "landing-page", value: "" }, -1)
                }
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                PCT
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages?.map((page, index) => (
                    <MenuItem
                      key={page?.value}
                      onClick={() => openEntity(page, index)}
                    >
                      <Typography textAlign="center">{page?.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <ElectricBoltIcon
                sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
              />
              <Typography
                variant="h5"
                noWrap
                onClick={() =>
                  openEntity({ label: "landing-page", value: "" }, -1)
                }
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                PCT
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages?.map((page, index) => (
                  <Button
                    //   selected={selectedIndex === index}
                    key={page.value}
                    onClick={() => openEntity(page, index)}
                    sx={{
                      my: 2,
                      borderRadius: "4px",
                      display: "block",
                      color: "white",
                      backgroundColor:
                        selectedIndex === index ? "#0075a2db" : "transparent",
                      "&:hover": {
                        backgroundColor: "#009edb",
                      },
                      margin: "2px",
                    }}
                  >
                    {page.label}
                  </Button>
                ))}
              </Box>
              {/* <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
            </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
        <Box
          width="100%"
          sx={
            {
              // background: "#a7f79e",
              // backgroundImage: "linear-gradient(to right, #a7f79e , #a7f79e)",
              // background:
              //   "linear-gradient(to right,#5fb38b 0%, #5fb38b 50%,#a7f79e 50%,#a7f79e 100%)",
              // paddingLeft: "100px",
            }
          }
          height="210px"
          textAlign="center"
          position="relative"
        >
          <img src={imageCover} width="100%" height="210px"></img>
          <Message navigation />
        </Box>
      </Box>
    )
  );
};
