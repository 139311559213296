export const contactlist = [
  {
    name: "”GEN-I TIRANA” SH.P.K",
    address: "Ish-Noli Busines Center, Rruga “Ismail Qemali”, nr.27, Tiranë",
    email: "Isufi@gen-1.si",
    tel: "0682045790",
  },
  {
    name: "”GSA”SH.P.K",
    address:
      "Njësia Bashkiake nr. 5, Rruga “Ibrahim Rugova”, Pallati nr. 64, Tiranë",
    email: "info@kurum.al",
    tel: "0692025491",
  },
  {
    name: "“KURUM INTERNATIONAL“ SH.A",
    address:
      "Tirane, Njesia Admin. Nr. 5, Rr. Ibrahim Rugova, Pallati nr. 14 KT, Green Park, Ap nr. 39, Kati 6",
    email: "Isufi@gen-1.si",
    tel: "+35554256500",
  },
  {
    name: "“AYEN ENERGY TRADING“ SH.A",
    address:
      "Njësia Bashkiake Nr. 2, Rruga “Papa Gjon Pali i II-të”, ABA Business Center, Kati 6, Nr. 607, Tiranë",
    email: "ayentrading@ayen.com.tr anisa@gmail.com",
    tel: "0695429321 / 0676092592",
  },
  {
    name: "“DEVOLL HYDROPOWER“ SH.P.K",
    address:
      "Rruga “Papa Gjon Pali II-të”, ABA Business Center, Zyra Nr. 1204, Tiranë",
    email: "info@devollhydropower.al",
    tel: "0692090090",
  },
  {
    name: "“LENGARICA & ENERGY“ SH.P.K",
    address: "Njësia Bashkiake nr.2, Rruga “Jul Variboba“, Vila nr.10, Tiranë",
    email: "halili@enso.at",
    tel: "44500765/ 0686071053",
  },
  {
    name: "“ALBANIAN GREEN ENERGY” SH.P.K",
    address:
      "Njësia Bashkiake Nr.2, Rruga ““Abdi Toptani“, Torre Drin, Ap. 61, Tiranë",
    email: "info@age-sjalbania.eu",
    tel: "0692034795",
  },
  {
    name: "“FURNIZUESI I TREGUT TË LIRË” SH.A",
    address:
      "(FTL sh.a) Bulevardi “Gjergj Fishta”, Ndërtesa Nr. 88, H. I, Kati IV, Njësia Administrative Nr. 7, 1023, Tiranë",
    email: "rexhepi@ftl.al",
    tel: "0695617847",
  },
  {
    name: "“ENER TRADE” SH.P.K",
    address: "Rruga e Elbasanit, Objekti Park Gate, Kati i I-rë, Tiranë",
    email: "trading@enertrade.al",
    tel: "0682061066",
  },
  {
    name: "“RENRGY TRADING GROUP” SH.P.K",
    address: "Rruga “Dervish Hima”, Nr. 1, Kati 1, Tiranë",
    email: "-",
    tel: "045600008",
  },
  {
    name: "KESH SH.A",
    address: "Viktor Eftimiu Tiranë, 1010, Tiranë",
    email: "mail@.kesh.al",
    tel: "2222065",
  },
  {
    name: "“EZ-5 ENERGY” SH.P.K",
    address:
      "Lagjja 14, Ura Dajlanit, Autostrada Durrës - Tiranë, Km. 1, Zona Kadastrale 8515, Nr i Pasurise 8/2572 Durrës",
    email: "info@ez-5energy.com",
    tel: "0676027641",
  },
  {
    name: "AYEN AS ENERGJI SH.A",
    address:
      "BLV.DESHMORET E KOMBIT, QENDRA E BIZNESIT TWIN TOWERS, KLL 2, KATI 10, Tiranë",
    email: "ayenas@ayen.com.tr",
    tel: "0674003686",
  },
  {
    name: "“DRAGOBIA ENERGY“ SH.P.K",
    address:
      "Njësia Bashkiake Nr. 2, Rruga “Papa Gjon Pali II”, Ndërtesë Nr. 12, ABA Business Center, Kati i 7, Kodi Postar 1010, Tiranë",
    email: "info@gener2.al",
    tel: "042248313",
  },
  {
    name: "“ALBESP TRADING & CONSULTING“ SH.P.K",
    address:
      "Njesia bashkiake nr. 5, Rruga Vaso Pasha, Hyrja 1, Ap. 10, zona kadastrale 8270, pasuria nr. 5/325+1-10, Tiranë",
    email: "sadikaj@albesptc.al",
    tel: "0682031301",
  },
  {
    name: "“NOA ENERGY TRADE“ SH.P.K",
    address: "Rr. Nikolla Tupe, Nr. 3, 1001 Tiranë",
    email: "info@noaholding.com",
    tel: "044500378 / 0696533504 / 0692055101 / 042257661",
  },
  {
    name: "“NATYRE ENERGY” SH.P.K",
    address: "Rruga Prush Vaqarr, Tirana",
    email: "cekrezi@natyraenergy.com",
    tel: "0672071032",
  },
  {
    name: "“G.S.K” SH.P.K",
    address: "Lagja 14, Ura e Dajlanit, Durrës",
    email: "ademaj1@gmail.com",
    tel: "0698001240",
  },
  {
    name: "“PROINFINIT CONSULTING” SH.P.K ",
    address: "Kompleksi Kika 2,Kati 1,1C/8, Tiranë",
    email: "consulting@proinfinit.com",
    tel: "0696033327 / 0682153092",
  },
  {
    name: "“ENERGY24“ SH.P.K",
    address: "Rr. Barigada 8, Pallati LID, Kati 6, Tiranë",
    email: "finance@c21.al",
    tel: "0694050022",
  },
  {
    name: "“FSHU” SH.A",
    address: "Bulevardi: Gjergj Fishta Ndërtesa nr.88, H1, Tiranë",
    email: "Elton.sevrani@oshee.al",
    tel: "0697037370",
  },
];

export const qalist = [
  {
    question: "Çfarë të drejtash keni?",
    link: "https://ere.gov.al/doc/Cfare te drejtash keni.pdf",
  },
  {
    question: "Si te beni nje lidhje me rrjetin e Shperndarjes?",
    link: "https://ere.gov.al/doc/Si te beni nje lidhje me rrjetin e Shperndarjes.pdf",
  },
  {
    question: "Si te beni nje lidhje ne tension te ulet?",
    link: "https://ere.gov.al/doc/Si te beni nje lidhje ne tension te ulet.pdf",
  },
  {
    question: "Tarifat per lidhje ne tension te ulet 0-20kw",
    link: "https://ere.gov.al/doc/Tarifat ne tension te ulet.pdf",
  },
  {
    question: "Tarifat per lidhje ne tension te ulet 21-50 kw",
    link: "https://ere.gov.al/doc/tarifat ne tension te ulet 21-50 kW.pdf",
  },
  {
    question: "Procedura per ndryshimin e furnizuesit",
    link: "https://ere.gov.al/doc/procedurat per ndryshimin e furnizuesit.pdf",
  },
  {
    question: "Cfare duhet te permbaje fatura e energjise?",
    link: "https://ere.gov.al/doc/cfare duhet te permbaje fatura e energjise.pdf",
  },
  {
    question:
      "Cfare kushtesh duhet te plotesoni per te lidhur nje kontrate energjie elektrike?",
    link: "https://ere.gov.al/doc/cfare kushtesh duhet te plotesoni per te lidhur nje kontrate energjie elektrike.pdf",
  },
  {
    question: "Si mund te lidhni nje kontrate energjie elektrike?",
    link: "https://ere.gov.al/doc/Si mund te lidhni nje kontrate energjie elektrike.pdf",
  },
  {
    question:
      "Si të bëni një lidhje me rrjetin e shpërndarjes 50-100 kwh në tension të ulët?",
    link: "https://ere.gov.al/doc/1.Si te beni nje lidhje me rrjetin e shpeerndarjes 50-100 kwh ne tension te ulet.pdf",
  },
  {
    question: "Ku ndryshon nderpreja e furnizimit nga furnizuesi dhe rilidhja?",
    link: "https://ere.gov.al/doc/2.Ku ndryshon nderpreja e furnizimit nga furnizuesi dhe rilidhja.pdf",
  },
  {
    question: "Cfare duhet te beni per te qene veteprodhues?",
    link: "https://ere.gov.al/doc/3.Cfare duhet te beni per te qene veteprodhues.pdf",
  },
  {
    question:
      "Si te beni nje lidhje me rrjetin e shperndarjes ne tension te mesem?",
    link: "https://ere.gov.al/doc/4.Si te beni nje lidhje me rrjetin e shperndarjes ne tension te mesem.pdf",
  },
  {
    question: "Cfare eshte matesi inteligjent dhe si funksionon ai?",
    link: "https://ere.gov.al/doc/5.Cfare eshte matesi inteligjent dhe si funksionon ai.pdf",
  },
  {
    question:
      "Ne cilat raste behet përfundimi i kontratës me kërkesë të klientit?",
    link: "https://ere.gov.al/doc/6.Ne cila raste behet perfundimi i kontrates me kerkese te klientit.pdf",
  },
  {
    question: "Kur nderpritet energjia elektrike?",
    link: "https://ere.gov.al/doc/7.Kur nderpritet energjia elektrike.pdf",
  },
  {
    question: "Kur dhe si duhet të paguani faturën e energjisë elektrike?",
    link: "https://ere.gov.al/doc/8.Kur dhe si duhet te paguani faturen e energjise elektrike.pdf",
  },
  {
    question: "Cfare rregullon kontrata e furnizimit?",
    link: "https://ere.gov.al/doc/9.Cfare rregullon kontrata e furnizimit.pdf",
  },
  {
    question: "Cilat jane metodat e pagesës së energjisë elektrike?",
    link: "https://ere.gov.al/doc/10.Cilat jane metodat e pageses se energjise elektrike.pdf",
  },
  {
    question:
      "Cilat jane tarifat për lidhje me rrjetin për konsumatorë jo familjarë në tension të mesëm?",
    link: "https://ere.gov.al/doc/11.Cilat jane tarifat per lidhje me rrjetin per konsumatore jo familjare ne tension te mesem.pdf",
  },
];
