import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBar } from "../navigation/TopBar";
import { PageSection } from "../components/PageSection";
import QaCard from "../components/QaCard";
import { qalist } from "../store/data";

export const Faq = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <TopBar /> */}
      <Box flexDirection="row" display="flex" width="100%">
        <PageSection>
          {" "}
          <Typography gutterBottom variant="h4">
            Pyetje me të shpeshta (FAQ)
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            sx={{ marginBottom: "16px" }}
          >
            {" "}
            Më poshtë do të gjeni pergjigje të disa pyetjeve më të shpeshta:
          </Typography>
          {qalist.map((item) => (
            <QaCard key={item?.question} data={item} />
          ))}
        </PageSection>
      </Box>
    </>
  );
};
