import React, { useEffect, useState } from "react";
import FeedbackIcon from "@mui/icons-material/Feedback";
import axios from "axios";
import { APP_URL } from "../services/http-common";

export const Message = (props) => {
  const [message, setMessage] = useState("<p></p>\n");
  useEffect(() => {
    axios.get(APP_URL + "public/dashboard-mesasge").then((response) => {
      const messageTemp = response?.data?.content;
      setMessage(messageTemp);
    });
  }, []);
  return (
    <>
      {message !== "<p></p>\n" && (
        <div
          style={{
            width: props.width ? props.width : "350px",
            background: "#ffffffde",
            borderRadius: "4px",
            position: "absolute",
            top: props.top ? props.top : "16px",
            right: " 16px",
            maxHeight: "142px",
            overflow: "auto",
            minHeight: props?.navigation ? "50%" : "auto",
            padding: "12px",
            paddingLeft: "12px",
            paddingRight: "12px",
            display: "flex",
          }}
        >
          <FeedbackIcon
            sx={{ color: "rgb(0 143 198 / 80%)", transform: "scaleX(-1)" }}
          />
          <div
            style={{
              fontSize: "14px",
              color: "#004c69",
              textAlign: "left",
              marginLeft: "5px",
              marginTop: "-16px",
              fontFamily: "Inter",
              // background: "#ffffffde",
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      )}
    </>
  );
};
