import { createTheme } from "@mui/material/styles";
import { textAlign } from "@mui/system";
import RalewayWoff2 from "./fonts/basier/raleway-regular.woff2";
import BasierCircleWoff2 from "./fonts/basier/BasierCircle-Regular.woff2";
import BasierCircleMediumWoff2 from "./fonts/basier/BasierCircle-Medium.woff2";

const light = createTheme({
  palette: {
    primary: {
      main: "#008fc6",
      white: "#ffffff",
      success: "#4EB65A",
      dark: "#008fc6",
      error: "#ff4e4e",
    },
    success: { main: "#4EB65A" },
    secondary: {
      main: "#111",
    },
    background: {
      default: "#f6f8fc",
    },
  },
  // zIndex: 1,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ["Inter", "Basier Circle Medium"].join(","),
    h3: {
      fontSize: "40px",
      color: "#091B3D",
      fontFamily: "Inter",
      fontWeight: 600,
      lineHeight: "50px",
    },
    h4: {
      fontSize: "30px",
      color: "#091B3D",
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "37px",
    },
    h5: {
      fontSize: "20px",
      color: "#091B3D",
      fontFamily: "Inter",
    },
    label: {
      color: "#575757",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 700,
    },
    body: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Inter",
    },
  },
  spacing: 8,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "16px",
          boxShadow: "none",
          borderRadius: "8px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: "Inter",
          minHeight: 40,
          borderRadius: 3,
          padding: 0,
          background: "white",
          color: "#575757",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#008fc6",
            "& .Mui-error": {
              // background: "red",
              borderColor: "red",
            },
          },
          "& fieldset": { top: 0 },
          "& legend": {
            display: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
          },
          "fieldset ": {
            borderColor: "#dcdfe6",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root": {
            margin: 0,
            color: "black",
            fontWeight: 500,
            marginBottom: 4,
            position: "relative !important",
          },

          "&.MuiOutlinedInput-root": {
            "&.Mui-error": {
              background: "red",
              borderColor: "red",
              "&:hover": {
                backgroundColor: "red !important",
                borderColor: "red",
              },
            },

            "&:hover fieldset ": {
              // borderColor: colors.borderDefault,
              "& .Mui-error": {
                // background:
                //   colors.surfaceWarningCriticalSubdued + " !important",
                borderColor: "red",
              },
              "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "red",
                  // backgroundColor: colors.surfaceWarningCriticalSubdued,
                },
            },
          },

          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            borderRadius: 3,
            padding: "10px 8px",
            height: "20px",

            "&.Mui-disabled": {
              // backgroundColor: colors.surfaceDisabled,
              borderColor: "#808080",
            },
          },
          "&:hover": {
            cursor: "auto",
            // backgroundColor: colors.surfaceHovered,
            borderColor: "#c0c4cc",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#c0c4cc",
              "& .Mui-error": {
                borderColor: "red",
              },
            },
            "& .Mui-error": {
              background: "red !important",
              borderColor: "red",
            },
          },

          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#808080",
            },
          },

          "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled": {
            // backgroundColor: colors.surfaceDisabled,
            color: "#808080",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-root.MuiInputLabel-root": {
            color: "#575757",
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            marginBottom: "4px",
            position: "relative",
            transform: "unset",
            WebkitTransform: "unset",
            "&.Mui-disabled": {
              color: "#808080",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Inter",
            lineHeight: "20px",
            color: "#808080",
            marginLeft: 0,
            marginRight: 0,
            marginTop: "4px",
            "&.Mui-error": {
              color: "red",
            },
            "&.Mui-disabled": {
              color: "#808080",
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&.MuiInputAdornment-root": {
            marginLeft: 8,
            marginRight: 8,
            "& .MuiTypography-root": {
              color: "black",
            },
            "&.Mui-disabled": {
              color: "	#808080",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: "transparent",
          borderRadius: "8px",
          "&.MuiIconButton-root.MuiIconButton-colorSecondary": {
            color: "white",
            border: "transparent",
            "&:hover": {
              color: "white",
            },
            "&:focus": {
              color: "white",
            },
          },
          "&.MuiButtonBase-root.MuiIconButton-root.IconButton-root:focus": {
            borderRadius: 0,
            "&:focus": {
              borderRadius: 0,
            },
          },
          "&.MuiIconButton-edgeEnd": {
            marginRight: "-8px",
            background: "transparent",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&.MuiList-root.MuiMenu-list.MuiMenuItem-root": {
            marginLeft: "6px",
            marginRight: "6px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root.MuiMenu-paper.MuiPopover-paper": {
            borderRadius: 3,
            border: "1px solid #e4e7ed",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12)",
            marginTop: "10px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 5,
          backgroundColor: "#000000",
          color: "#ffffff",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#000000",
          },
        },
      },
    },
  },
});
export default light;
