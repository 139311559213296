import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const ContactCard = ({ data }) => {
  const { name, address, tel, email } = data;
  return (
    <Card sx={{ minWidth: 275, marginBottom: "8px" }}>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" component="div">
          <b>Adresa:</b> {address}
        </Typography>
        <Typography variant="body2">
          <b>E-Mail:</b> {email} | <b>Tel:</b> {tel}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
