import * as React from "react";
import Card from "@mui/material/Card";
import { useMediaQuery } from "@mui/material";

export const PageSection = (props) => {
  const mobileMode = useMediaQuery("(max-width:900px)");
  const laptopMode = useMediaQuery(
    "((max-width: 1300px) and (min-width:801px))"
  );
  const largeMode = useMediaQuery("(min-width: 1301px)");
  return (
    <Card
      sx={{
        margin: mobileMode ? "20px Opx" : "20px 12%",
        width: "100%",
        minHeight: "400px",
        borderRadius: "4px",
        padding: "25px",
        boxShadow:
          "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      {props.children}
    </Card>
  );
};
