import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBar } from "../navigation/TopBar";
import { PageSection } from "../components/PageSection";

export const Complaint = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <TopBar /> */}
      <Box flexDirection="row" display="flex" width="100%">
        <PageSection>
          <Typography gutterBottom variant="h4">
            Kam një ankesë
          </Typography>
          <Typography variant="body1">
            Për ankesa ju lutem të kontaktoni në adresën elektronike:{" "}
            <a href="mailto: complaint@ere.gov.al">complaint@ere.gov.al</a>
          </Typography>
        </PageSection>
      </Box>
    </>
  );
};
