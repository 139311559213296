import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import light from "./theme/light";
import { CssBaseline } from "@mui/material";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AboutPct } from "./pages/AboutPct";
import { Complaint } from "./pages/Complaint";
import { Contacts } from "./pages/Contacts";
import { Faq } from "./pages/Faq";
import { HowToUse } from "./pages/HowToUse";
import { TopBar } from "./navigation/TopBar";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={light}>
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            background: "#F6F8FC",
            top: 0,
            bottom: 0,
          }}
        >
          <CssBaseline />
          <TopBar />
          <Routes basename="/">
            <Route path="/" element={<LandingPage />} exact />
            <Route path="/home" element={<Home />} />
            <Route path="/aboutPct" element={<AboutPct />} />
            <Route path="/complaint" element={<Complaint />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/howToUse" element={<HowToUse />} />
          </Routes>
          {/* <LandingPage /> */}
          {/* <Home /> */}
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
