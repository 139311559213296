import React, { useEffect } from "react";
import { Box, Button, FormControl, MenuItem, Typography } from "@mui/material";
import Select from "../UI/Select";
import imageCover from "../assets/background_ere.png";
import axios from "axios";
import { APP_URL } from "../services/http-common";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Message } from "../navigation/Message";

const LandingPage = () => {
  const [filter, setFilter] = React.useState("default");
  const [products, setProducts] = React.useState([]);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    axios.get(APP_URL + "public/product").then((data) => {
      setProducts(data?.data);
    });
  }, []);

  const handleHome = () => {
    navigate("/home", {
      state: { filterId: filter },
    });
  };

  const mobileMode = useMediaQuery("(max-width:800px)");

  return (
    <>
      <div
        width="auto"
        height="100%"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: mobileMode ? "no-repeat" : "round",
          backgroundImage: `url(${imageCover})`,
          height: "100vh",
        }}
      >
        <Message width="340px" top="30px" />
        <div
          style={{
            width: mobileMode ? "80%" : "45%",
            margin: "0 auto",
            textAlign: "center",
            paddingTop: mobileMode ? "200px" : "350px",
          }}
        >
          <FormControl
            fullWidth
            sx={{
              display: "flex",
              flexDirection: mobileMode ? "column" : "row",
              textAlign: "left",
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              onChange={handleChange}
              defaultValue="default"
              sx={{ color: filter === "default" ? "#bfbfbf" : "#575757" }}
            >
              <option key="default" value="default" disabled hidden>
                Kategoritë
              </option>
              {products.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              onClick={handleHome}
              sx={{
                marginLeft: mobileMode ? "0px" : "10px",
                marginTop: mobileMode ? "10px" : "0px",
                minWidth: "120px",
                textAlign: "left",
              }}
              variant="contained"
            >
              Kërkim
            </Button>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
