import * as React from "react";
import { styled } from "@mui/system";
import MUISlider from "@mui/material/Slider";

const Slider = styled(
  React.forwardRef((props, ref) => <MUISlider ref={ref} {...props} />),
  {
    name: "Slider",
    slot: "Root",
    overridesResolver: (props, styles) => [],
  }
)(({ theme }) => ({
  color: "#008fc6",
  height: 4,
  borderRadius: 0,
  ripples: false,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#008fc6",
    border: "1px solid #7fdbff",
    boxShadow: boxShadow,
    "&:focus, &:active, &:hover, &.Mui-active": {
      boxShadow: boxShadow,
      "@media (hover: none)": {
        boxShadow: boxShadow,
      },
    },
  },
  "& .Mui-focusVisible": {
    "&:focus, &:active, &:hover": {
      boxShadow: boxShadow,
    },
  },
  "& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
    boxShadow: boxShadow + " !important",
  },
  "& .MuiSlider-thumb::after": {
    height: 24,
    width: 24,
  },
  "& .MuiSlider-thumb::before": {
    height: 24,
    width: 24,
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: "black",
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#D8D8D9",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#D8D8D9",
    borderRadius: "50%",
    height: 12,
    width: 12,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#008fc6",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "black",
  },
}));

const boxShadow = "0 2px 4px rgba(0,0,0,0.24)";

export default Slider;
