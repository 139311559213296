import React, { useEffect, useState } from "react";
import CompanyCard from "./CompanyCard";
const CardList = ({ data, sortType = "asc" }) => {
  const [companiesList, setCompaniesList] = useState([]);
  useEffect(() => {
    const dataTemp =
      sortType === "asc"
        ? data.slice().sort((a, b) => a.currentPrice - b.currentPrice)
        : data
            .slice()
            .sort((a, b) => a.currentPrice - b.currentPrice)
            .reverse();
    setCompaniesList(dataTemp);
  }, [data, sortType]);

  return companiesList?.map((item, index) => (
    <CompanyCard data={item} key={index} companyName={item.company.name} />
  ));
};

export default CardList;
