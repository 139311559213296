import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBar } from "../navigation/TopBar";
import { PageSection } from "../components/PageSection";

export const HowToUse = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <TopBar /> */}
      <Box flexDirection="row" display="flex" width="100%">
        <PageSection>Si perdoret Platforma?</PageSection>
      </Box>
    </>
  );
};
